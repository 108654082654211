import { Component } from "@angular/core";
import versions from "../../../../package.json";
@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent {
  version = "";

  ngOnInit() {
    this.version = versions?.version;
  }
}
