import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { HelpMe } from "src/app/models/help-me";
import { GetListHelpAnswerService } from "src/app/services/common/get-list-help-answer.service";

@Component({
  selector: "app-aide-en-ligne",
  templateUrl: "./aide-en-ligne.component.html",
  styleUrls: ["./aide-en-ligne.component.css"],
})
export class AideEnLigneComponent implements OnInit, AfterViewInit, OnDestroy {
  unsubscribe$ = new Subject<void>();
  listeQuestionResponse: HelpMe[] = [];
  constructor(
    private getListHelpAnswerService: GetListHelpAnswerService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.getListeQuestionResponse();
  }
  ngAfterViewInit(): void {
    this.route.params.subscribe(async (params) => {
      const sectionId = params["section"];
      if (sectionId) {
         await new Promise((f) => setTimeout(f, 500));
         this.goDown(sectionId);
      }
    });
  }

  /**
   *
   *
   * @param {string} section
   * @memberof AideEnLigneComponent
   */
  goDown(section: string): void {
    const element = document.querySelector("#" + section);
    if (element != null) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }

  /**
   *
   *
   * @memberof AideEnLigneComponent
   */
  getListeQuestionResponse(): void {
    this.getListHelpAnswerService
      .getJSON()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (data) => {
          this.listeQuestionResponse = data;
        },
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
