/* istanbul ignore file */

import {BrowserModule} from "@angular/platform-browser";
import {CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, LOCALE_ID, NgModule,} from "@angular/core";
import {HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule} from "@angular/common/http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import localeFr from "@angular/common/locales/fr";
import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {NotFoundComponent} from "./components/not-found/not-found.component";
import {MainComponent} from "./components/main/main.component";
import {FooterComponent} from "./components/footer/footer.component";
import {HeaderComponent} from "./components/header/header.component";
import {BreadcrumbComponent} from "./components/breadcrumb/breadcrumb.component";
import {BreadcrumbModule} from "./components/breadcrumb/breadcrumb.module";
import {authInterceptorProviders} from "./services/common/auth.interceptor";
import {GlobalHandleHttpError} from "./services/common/GlobalErrorHandler.service";
import {AlerteMessageComponent} from "./components/alerte-message/alerte-message.component";
import {DirectiveModule} from "./directives/directive.module";
import {LoaderInterceptor} from "./services/common/loader.interceptor";
import {NgxSpinnerModule, NgxSpinnerService} from "ngx-spinner";
import {NgbDateAdapter, NgbDateParserFormatter, NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {DatePipe, registerLocaleData} from "@angular/common";
import {ModalMessageModule} from "./components/modal-message/modal-message.module";
import {AideEnLigneModule} from "./pages/aide-en-ligne/aide-en-ligne.module";
import {CookieService} from "ngx-cookie-service";
import {CustomDateAdapter} from "src/app/models/custom-date-adapter";
import {CustomDateParserFormatter} from "src/app/models/custom-date-parser-formatter";
import {AlerteAutoSaveComponent} from './components/alerte-auto-save/alerte-auto-save.component';
import {AlerteDataLoaderComponent} from './components/alerte-data-loader/alerte-data-loader.component';

registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    MainComponent,
    FooterComponent,
    HeaderComponent,
    BreadcrumbComponent,
    AlerteMessageComponent,
    AlerteAutoSaveComponent,
    AlerteDataLoaderComponent
  ],
  imports: [
    HttpClientXsrfModule.withOptions({ cookieName: "XSRF-TOKEN" }),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    BreadcrumbModule,
    DirectiveModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    NgbModule,
    ModalMessageModule,
    AideEnLigneModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {provide: NgbDateAdapter, useClass: CustomDateAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
    authInterceptorProviders,
    GlobalHandleHttpError,
    NgxSpinnerService,
    CookieService,
    DatePipe,
    {
      provide: LOCALE_ID,
      useValue: "fr-FR",
    },
    {
      provide: ErrorHandler,
      useClass: GlobalHandleHttpError,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
