<div class="robot-icon" *ngIf="showRobot">
  <div *ngIf="afficherAideEnlignePopup" class="fr-grid-row fr-grid-row--center robot-modal">
    <div class="fr-col-12">
      <div class="fr-col-12 robot-modal__header">
        <span class="fr-pl-1w"> Votre besoin concerne...</span>
        <button type="button" class="fr-btn fr-icon-close-circle-fill" style="color:white;margin-left: auto;"
          (click)="afficherAideEnlignePopup = !afficherAideEnlignePopup">
        </button>
      </div>
      <div class="fr-col-12 robot-modal__content">
        <div class="fr-accordions-group">
          <section class="fr-accordion" *ngFor="let thematique of listeQuestionResponse; let i = index">
            <h3 class="fr-accordion__title">
              <button type="button" class="fr-accordion__btn" style="color:#000091;" aria-expanded="false"
                [attr.aria-controls]="'accordion-' + i">{{thematique.label}}</button>
            </h3>
            <div class="fr-collapse" id="{{'accordion-'+i}}">
              <ng-container *ngFor="let item of thematique.questions; let j = index">
                <p class="robot__question" *ngIf="item.question.length > 0" (click)="openAideEnlignePage('section-'+i+j)">{{item.question}}</p>
              </ng-container>
            </div>
          </section>
        </div>
      </div>
      <div class=" fr-col-12 robot-modal__footer">
        <span class="fr-pl-1w">Une question, un problème ? Contactez votre référent métier.</span>
      </div>
    </div>
  </div>
  <div>
    <img class="robot__logo" (click)="afficherAideEnlignePopup = !afficherAideEnlignePopup"
      src="../../../assets/icon-help.svg" alt="icone-besoin-aide" />
  </div>
</div>