import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HelpMe } from 'src/app/models/help-me';

@Injectable({
  providedIn: "root",
})
export class GetListHelpAnswerService {
  private _jsonURL = "../../../assets/help.json";

  constructor(private http: HttpClient) {}

   getJSON(): Observable<HelpMe[]> {
    return this.http.get<HelpMe[]>(this._jsonURL);
  }
}
