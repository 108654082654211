<footer class="fr-footer fr-pl-15w fr-pr-15w" role="contentinfo" id="footer" style="background-color: inherit">
  <div>
    <div class="fr-footer__body">
      <div class="fr-footer__brand">
        <div>
          <ul class="fr-footer__content-list" style="align-items: center">
            <li class="fr-footer__content-item">
              <a
                href="https://www.collectivites-locales.gouv.fr/"
                target="_blank"
                title="Site DGCL - Nouvelle fenêtre"
                rel="noopener noreferrer">
                <img src="../../../assets/Logo_dgcl_bleu-noir 1.svg" class="fr-ml-2w" alt="DGCL" style="width: 240px" />
              </a>
            </li>
            <li class="fr-footer__content-item">
              <a
                href="https://www.modernisation.gouv.fr/transformer-laction-publique/fonds-pour-la-transformation-de-laction-publique"
                title="Site fond pour la transformation de l'action publique - Nouvelle fenêtre"
                rel="noopener noreferrer"
                target="_blank">
                <img src="../../../assets/ftap.jpg" class="fr-ml-2w" alt="ftap" style="width: 80px" />
              </a>
            </li>
            <li class="fr-footer__content-item">
              <a
                href="https://www.economie.gouv.fr/plan-de-relance"
                title="Site plan de relance - Nouvelle fenêtre"
                target="_blank"
                rel="noopener noreferrer">
                <img src="../../../assets/Logotype-vert 1.svg" class="fr-ml-2w" alt="France relance" />
              </a>
            </li>
            <li class="fr-footer__content-item">
              <img
                alt="Fiancement UE"
                class="fr-ml-2w"
                src="../../../assets/FR-Finance-par-l-Union-europeenne_PANTONE 1.svg" />
            </li>
          </ul>
        </div>
      </div>

      <div class="fr-footer__content footer_text">
        <ul class="fr-footer__content-list">
          <li class="fr-footer__content-item">
            <a class="fr-footer__content-link" href="https://legifrance.gouv.fr" target="_blank" rel="noopener noreferrer"
              >legifrance.gouv.fr</a
            >
          </li>
          <li class="fr-footer__content-item">
            <a class="fr-footer__content-link" href="https://gouvernement.fr" target="_blank" rel="noopener noreferrer"
              >gouvernement.fr</a
            >
          </li>
          <li class="fr-footer__content-item">
            <a class="fr-footer__content-link" href="https://service-public.fr" target="_blank" rel="noopener noreferrer"
              >service-public.fr</a
            >
          </li>
          <li class="fr-footer__content-item">
            <a class="fr-footer__content-link" href="https://data.gouv.fr" target="_blank" rel="noopener noreferrer"
              >data.gouv.fr</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="fr-footer__bottom">
      <ul class="fr-footer__bottom-list">
        <li class="fr-footer__bottom-item">
          <a
            class="fr-footer__bottom-link"
            [routerLink]="'/accessibilite'"
            routerLinkActive="active"
            ariaCurrentWhenActive="page"
            [routerLinkActiveOptions]="{ exact: true }"
            >Accessibilité : non conforme</a
          >
        </li>
        <li class="fr-footer__bottom-item">
          <a
            class="fr-footer__bottom-link"
            [routerLink]="'/donnees-personnelles'"
            routerLinkActive="active"
            ariaCurrentWhenActive="page"
            [routerLinkActiveOptions]="{ exact: true }"
            >Données personnelles</a
          >
        </li>
        <li class="fr-footer__bottom-item">
          <a
            class="fr-footer__bottom-link"
            [routerLink]="'/contact'"
            routerLinkActive="active"
            ariaCurrentWhenActive="page"
            [routerLinkActiveOptions]="{ exact: true }"
            >Contact</a
          >
        </li>
        <li class="fr-footer__bottom-item">
          <a
            class="fr-footer__bottom-link"
            [routerLink]="'/mentions-legales'"
            routerLinkActive="active"
            ariaCurrentWhenActive="page"
            [routerLinkActiveOptions]="{ exact: true }"
            >Mentions légales</a
          >
        </li>
      </ul>
      <div class="fr-footer__bottom-copy">
        <p>
          Sauf mention contraire, tous les contenus de ce site sont sous
          <a href="https://github.com/etalab/licence-ouverte/blob/master/LO.md" rel="noopener noreferrer" target="_blank"
            >licence etalab-2.0</a
          >
          / V{{ version }}
        </p>
      </div>
    </div>
  </div>
</footer>
