<header role="banner" class="fr-header fixed-top-header fr-mb-2w">
    <div class="fr-header__body ">
        <div class="fr-container--fluid fr-pl-15w fr-pr-15w">
            <div class="fr-header__body-row">
                <div class="fr-header__brand fr-enlarge-link">
                    <div class="fr-header__brand-top">
                        <div class="fr-header__logo">
                            <a [routerLink]="'/'" title="Accueil">
                                <p class="fr-logo fr-logo--lg">
                                    République<br>
                                    Française<br>
                                </p>
                            </a>
                        </div>
                        <div class="fr-header__navbar">
                            <button class="fr-btn--menu fr-btn" type="button" data-fr-opened="false"
                                aria-controls="modal-491" aria-haspopup="menu" id="button-492" title="Menu">
                                Menu
                            </button>
                        </div>
                    </div>
                    <div class="fr-header__service"><a [routerLink]="'/'" title="Accueil">
                            <!-- <p class="fr-header__service-title">Contrats-Territoires</p> -->
                            <img src="../../../assets/logo-complet-100.jpg" alt="logo Contrats-Territoires"
                                style="max-height: 135px;">
                        </a>
                        <!-- <p class="fr-header__service-tagline">Le site officiel de l'administration française</p> -->
                    </div>
                </div>
                <ng-container *ngIf="userIsLogged">

                    <div class="fr-header__tools">

                        <div class="fr-header__tools-links">
                            <ul class="fr-btns-group" style="  flex-flow: column wrap;">
                                <li style="margin-left: auto;">
                                    <a [routerLink]="'/accounts/logout'" (click)="logout()"
                                        aria-label="Bouton déconnecter" class="fr-btn txt-blue"><em
                                            class="fr-icon-logout-box-r-fill"></em>
                                        Déconnexion</a>
                                </li>
                                <li>
                                    <a class="fr-btn txt-blue"><em class="fr-icon-account-circle-fill"></em>
                                        {{firstName | titlecase}} {{lastName | uppercase}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="fr-header__menu fr-modal" id="modal-491" aria-labelledby="button-492">
        <div class="fr-container--fluid fr-pl-15w fr-pr-15w">
            <button class="fr-btn--close fr-btn" type="button" aria-controls="modal-491" title="Fermer">
                Fermer
            </button>
            <div class="fr-header__menu-links">
            </div>
            <nav class="fr-nav" id="navigation-494" role="navigation" aria-label="Menu principal" *ngIf="userIsLogged">
                <ul class="fr-nav__list" *ngIf="show">
                    <li class="fr-nav__item" *ngFor="let item of tabs">
                        <a class="fr-nav__link" [routerLink]="[item.path]" [attr.aria-label]="item.label"
                            routerLinkActive="active" ariaCurrentWhenActive="page"
                            [routerLinkActiveOptions]="{ exact: true }" [appHasRole]="item.role"
                            [operatorForRole]='"OR"'>{{item.label}}</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</header>
