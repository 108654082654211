import {HttpClient} from "@angular/common/http";
import {Injectable, OnDestroy} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {environment} from "../../../environments/environment";
import {Token} from "src/app/models/token";
import {Router} from "@angular/router";

import {Profil} from "../../models/user";
import {Authentication, ResetPassword} from "src/app/models/authentication";
import * as moment from "moment-timezone";
import {CookieService} from "ngx-cookie-service";
import {ResetPasswordLink} from "src/app/models/resetPasswordLink";
import {takeUntil} from "rxjs/operators";
import {AlertMessage, TypeAlert} from "src/app/models/alert-message";
import {AlertMessageService} from "src/app/components/alerte-message/alert-message.service";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService implements OnDestroy {
  private refreshTokenTimeout: ReturnType<typeof setTimeout>;

  isloggedUser: Subject<boolean>;
  isSendRefreshToken = false;
  unsubscribe$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService,
    private alertMessageService: AlertMessageService
  ) {
    this.isloggedUser = new Subject<boolean>();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getUserId(): string {
    return this.cookieService.get("userId");
  }

  isLoggedIn(): boolean {
    const expiration: string = this.cookieService.get("expires_at");
    if (expiration && expiration.length > 0) {
      const expires = new Date(expiration);
      return moment().isBefore(expires);
    } else {
      return false;
    }
  }

  authenticate(loginData: Authentication): Observable<Token> {
    const body = loginData;
    return this.http.post<Token>(
      `${environment.apiBaseUrl}/authentication/signin`,
      body
    );
  }

  checkLogin(email: string): Observable<{ message: string }> {
    return this.http.get<{ message: string }>(
      `${environment.apiBaseUrl}/authentication/user/${email}`
    );
  }

  logout(): void {
    this.http
      .get<any>(`${environment.apiBaseUrl}/authentication/logout`)
      .subscribe({
        next: () => {
          this.isloggedUser.next(false);
          this.stopRefreshTokenTimer();
          this.cookieService.deleteAll("/");
          this.router.navigate(["/accounts/login"]);
        },
      });
    localStorage.clear();
    this.cookieService.deleteAll();
  }

  resetPassword(resetPassword: ResetPassword): any {
    return this.http.post<any>(
      `${environment.apiBaseUrl}/authentication/reset-password`,
      resetPassword
    );
  }

  setSession(token: Token): void {
    this.cookieService.set("firstName", token.firstName, new Date(token.exp), "/");
    this.cookieService.set("lastName", token.lastName, new Date(token.exp), "/");
    this.cookieService.set("userId", token.userId, new Date(token.exp), "/");
    this.cookieService.set("expires_at", token.exp, new Date(token.exp), "/");
    this.cookieService.set("privileges", token.privileges, new Date(token.exp), "/");
    this.cookieService.set("profil", token.profil, new Date(token.exp), "/");
    localStorage.setItem(
      "collectStartDate",
      new Date(new Date().getFullYear() - 1, 0, 1).toString()
    );
    localStorage.setItem(
      "collectEndDate",
      new Date(new Date().getFullYear(), 11, 31).toString()
    );
    this.isloggedUser.next(true);
    this.isSendRefreshToken = false;
  }

  startRefreshTokenTimer(): void {
    setInterval((): void => {
      if (!this.isSendRefreshToken && this.isOneMinuteBeforeExpire(this.cookieService.get("expires_at"))) {
        this.isSendRefreshToken = true;
        this.refreshToken()
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe({
              next: (resp: Token): void => {
                this.setSession(resp);
              },
              error: (): void => {
                const alertMessage: AlertMessage = {
                  type: TypeAlert.ERROR,
                  title: "Erreur",
                  text: "Une erreur est survenu lors de la mise à jour du token. Veuillez vous reconneter."
                }
                 this.alertMessageService.pushAlerteMessage(alertMessage);
              }
            }
          )
      }
    }, 1000)
  }

  refreshToken(): Observable<Token> {
    return this.http.get<Token>(
      `${environment.apiBaseUrl}/authentication/refreshtoken`
    );
  }

  requestLink(resetPassword: ResetPasswordLink): Observable<any> {
    return this.http.post<any>(
      `${environment.apiBaseUrl}/authentication/request-link`,
      resetPassword
    );
  }

  stopRefreshTokenTimer(): void {
    clearTimeout(this.refreshTokenTimeout);
  }

  getUserProfil(): Profil {
    return {
      id: null,
      profil: this.cookieService.get("profil"),
      subProfil: null,
      privileges: [],
    };
  }

  private isOneMinuteBeforeExpire(expiresAt: string): boolean {
    const expiration = moment(new Date(expiresAt));
    const now = moment(new Date());
    return moment.duration(expiration.diff(now)).asMinutes() < 1;
  }
}
