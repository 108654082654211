<div id="top">
    <noscript>
        <div class="fr-container--fluid fr-mb-10v">
            <p>
                <strong>Javascript désactivé</strong>
                <br>
                Javascript doit être activé dans votre navigateur pour utiliser ce service
            </p>
        </div>
    </noscript>
    <div class="fr-skiplinks">
        <div class="fr-container--fluid ">
            <ul class="fr-skiplinks__list fr-container--fluid fr-pl-15w fr-pr-15w">
                <li><a class="fr-link" href="#footer">Aller au pied de page</a></li>
            </ul>
        </div>
    </div>
</div>
<div>
    <app-header></app-header>
</div>

<main role="main" class="fr-container--fluid fr-pl-15w fr-pr-15w" style="min-height: 600px; position: relative;">
    <div>
        <app-breadcrumb></app-breadcrumb>
       <div class="fr-alert fr-alert--error fr-mb-3w" *ngIf="!isConnected">
            <p>Erreur : Vous n'êtes plus connecté à internet.</p>
       </div>
        <app-alerte-message></app-alerte-message>
        <app-alerte-auto-save></app-alerte-auto-save>
        <app-alerte-data-loader></app-alerte-data-loader>
        <router-outlet></router-outlet>
    </div>
</main>
<div class="fr-container--fluid">
    <app-footer></app-footer>
</div>
<ngx-spinner type="ball-spin">
</ngx-spinner>
<ng-container *ngIf="isLoggedIn">
    <app-robot-aide-en-ligne></app-robot-aide-en-ligne>
</ng-container>
