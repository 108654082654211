import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import _ from 'lodash';
import { AlertMessageService } from 'src/app/components/alerte-message/alert-message.service';
import { AlertMessage, TypeAlert } from 'src/app/models/alert-message';
import { AuthenticationService } from '../api/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private alerteService: AlertMessageService
    ){}

  canActivate(route: ActivatedRouteSnapshot): any {
    const roleAllowed = this.authenticationService.getUserProfil().profil;
    if(route.data.roles) {
      for (const habilitation of route.data.roles) {
        const include: boolean = _.includes(roleAllowed, habilitation)
        if (include) {
          return true
        }
      }
    }  
    const alertMessage: AlertMessage = {
      title: "Accès refusé",
      text: "L'accès à cette ressource est interdit.",
      type: TypeAlert.ERROR,
    };
    this.alerteService.pushAlerteMessage(alertMessage);
    this.router.navigate(['']);
    return false;
  }
  
}
