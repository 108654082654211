import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import {
  BreadcrumbItem,
  BreadcrumbService,
} from "../../services/common/breadcrumb.service";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.css"],
})
export class BreadcrumbComponent  {
  breadcrumbs$: Observable<BreadcrumbItem[]>;
  homePage = false;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private router: Router
  ) {
    this.breadcrumbs$ = this.breadcrumbService.items$;
  }

  getHomePage(): boolean {
    return this.router.url == "/";
  }
}
