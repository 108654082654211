import {Injectable} from '@angular/core';
import {Subject} from "rxjs";
import {AlertMessage} from "src/app/models/alert-message";

@Injectable({
  providedIn: 'root'
})
export class AlerteAutoSaveService {
  message: Subject<AlertMessage>;

  constructor() {
    this.message = new Subject<AlertMessage>();
  }

  pushMessage(message: AlertMessage): void {
    this.message.next(message);
  }
}
