import { Directive, HostBinding, Host } from "@angular/core";
import { RouterLinkActive } from "@angular/router";
@Directive({
  selector: "[routerLinkActive]",
})
export class RlaPageDirective {
  constructor(@Host() private rla: RouterLinkActive) {}
  get isActive(): boolean {
    return this.rla.isActive;
  }

  @HostBinding("attr.aria-current") get ariaCurrent():any {
    return this.isActive ? "Page" : undefined;
  }
}
