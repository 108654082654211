import {Component, OnDestroy, OnInit} from "@angular/core";
import {AuthenticationService} from "../../services/api/authentication.service";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {ActivatedRoute, NavigationStart, Router} from "@angular/router";
import {CookieService} from "ngx-cookie-service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  userIsLogged = false;
  firstName: string;
  lastName: string;
  userId: string;
  tabs = [
    {label: "Accueil", path: "/"},
    {
      label: "Administrer le SI",
      path: "/administration-SI",
      role: ["ROLE_ADMIN_NATIONAL"],
    },
    {
      label: "Gestion des utilisateurs",
      path: "/gestion-utilisateurs",
      role: ["ROLE_ADMIN_REGIONAL"],
    },
    {label: "Maquettes financières", path: "/maquettes-financieres"},
    {label: "Données d'exécution financière", path: "/collecte-donnees"},
    {label: "Tableau de bord", path: "/tableau-de-bord"},
    {label: "Aide en ligne", path: "/aide-en-ligne"},
  ];
  show = true;

  constructor(
    private authentificationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private cookieService: CookieService
  ) {
  }

  ngOnInit(): void {
    this.getUserInformation();
    this.watcherUserLoggedStatut();
    this.router.events.subscribe((event) => {
      if (
        event instanceof NavigationStart &&
        this.userId != this.cookieService.get("userId")
      ) {
        this.show = false;
        this.getUserInformation();
        setTimeout(() => (this.show = true));
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getUserInformation(): void {
    this.userIsLogged = this.authentificationService.isLoggedIn();
    if (this.userIsLogged) {
      this.setUserInformation();
      this.authentificationService.startRefreshTokenTimer();
    }
  }

  watcherUserLoggedStatut(): void {
    this.authentificationService.isloggedUser
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.userIsLogged = data;
        if (this.userIsLogged) {
          this.authentificationService.startRefreshTokenTimer();
          this.setUserInformation();
        } else {
          this.firstName = "";
          this.lastName = "";
        }
      });
  }

  private setUserInformation() {
    this.firstName = this.cookieService.get("firstName");
    this.userId = this.cookieService.get("userId");
    this.lastName = this.cookieService.get("lastName");
  }

  logout(): void {
    this.userIsLogged = this.authentificationService.isLoggedIn();
  }
}
