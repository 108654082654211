<nav role="navigation" class="fr-breadcrumb fr-pt-2w" aria-label="vous êtes ici :">
  <button type="button" class="fr-breadcrumb__button" aria-expanded="false" aria-controls="breadcrumb">Voir le fil
    d'Ariane</button>
  <div class="fr-collapse" id="breadcrumb">
    <ol class="fr-breadcrumb__list">
      <!-- Display the parent pages, except current page -->
      <li>
        <a class="fr-breadcrumb__link" [routerLink]="'/'">Accueil</a>
      </li>
      <ng-container *ngFor="let item of breadcrumbs$ | async; let last = last">
        <li *ngIf="!last">
          <a class="fr-breadcrumb__link" [routerLink]="item?.path">{{item?.label}}</a>
        </li>
      </ng-container>

      <!-- Display the current page-->
      <li>
        <a class="fr-breadcrumb__link" aria-current="page" *ngIf="breadcrumbs$ | async as items">{{items[items.length
          - 1]?.label}}</a>
      </li>
    </ol>
  </div>
</nav>
