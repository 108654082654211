/* istanbul ignore file */

import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "src/app/services/common/auth-guard";
import { AideEnLigneComponent } from "./aide-en-ligne.component";

const routes: Routes = [
  {
    path: "",
    component: AideEnLigneComponent,
    data: {
      breadcrumb: "Aide en ligne",
    },
    canActivate: [AuthGuard],
  },
  {
    path: ":section",
    component: AideEnLigneComponent,
    data: {
      breadcrumb: "Aide en ligne",
    },
    canActivate: [AuthGuard],
  },
];

export const AideEnLigneRoutes = RouterModule.forChild(routes);
