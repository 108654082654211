/* istanbul ignore file */

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AideEnLigneComponent } from "./aide-en-ligne.component";
import { AideEnLigneRoutes } from "./aide-en-ligne.routing";
import { RobotAideEnLigneComponent } from "src/app/components/robot-aide-en-ligne/robot-aide-en-ligne.component";

@NgModule({
  imports: [CommonModule, AideEnLigneRoutes],
  declarations: [AideEnLigneComponent, RobotAideEnLigneComponent],
  exports: [RobotAideEnLigneComponent],
})
export class AideEnLigneModule {}
