import {Component, NgZone, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {AlerteDataLoaderService} from "src/app/components/alerte-data-loader/alerte-data-loader.service";
import {animate, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-alerte-data-loader',
  templateUrl: './alerte-data-loader.component.html',
  styleUrls: ['./alerte-data-loader.component.css'],
  animations: [
    trigger("inOutAnimation", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate(".2s ease-out", style({ opacity: 1 })),
      ]),
      transition(":leave", [
        style({ opacity: 1 }),
        animate(".2s ease-in", style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class AlerteDataLoaderComponent implements OnInit {
  message: string;
  isDisplay = false;
  unsubscribe$ = new Subject<void>();
  tooltipMessage = "Le chargement peut prendre un certain temps en fonction des données à charger."
  constructor(
    private dataLoaderService: AlerteDataLoaderService,
    private zone: NgZone) { }

  ngOnInit(): void {
    this.watcherMessage();
    this.watcherOpen();
    this.isDisplay = false;
  }
  watcherMessage(): void {
    this.dataLoaderService.message.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.zone.run(() => this.message = data);
    });
  }

  watcherOpen(): void {
    this.dataLoaderService.open.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      if(!data){
        this.zone.run(() => {
          setTimeout(() => (this.isDisplay = false), 200);
        });
      } else {
        this.isDisplay = true;
      }
    });
  }

  closeLoaderAlert(): void {
    this.zone.run((): void => {
      setTimeout((): boolean => (this.isDisplay = false), 200);
      this.dataLoaderService.pushLockButton(false);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
