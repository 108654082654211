import { Directive, ElementRef, Input, OnInit } from "@angular/core";
import { Profil } from "../models/user";
import { AuthenticationService } from "../services/api/authentication.service";

@Directive({
  selector: "[appHasRole]",
})
export class HasRoleDirective implements OnInit {
  @Input() appHasRole: Array<string>;
  @Input("operatorForRole") operatorForRole: string;

  constructor(
    private el: ElementRef,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    const user: Profil = this.authenticationService.getUserProfil();
    if (!user.profil || (this.appHasRole && this.appHasRole.indexOf(user.profil) == -1)) {
      this.el.nativeElement.style.display = "none";
    }
  }
}
