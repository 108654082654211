/* istanbul ignore file */

import {Component, isDevMode, OnInit} from "@angular/core";
import {AuthenticationService} from "./services/api/authentication.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {Clipboard} from "@angular/cdk/clipboard";
import { ConnectionService } from "./services/api/connection.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  title = "SICPER";
  isLoggedIn: boolean;
  isConnected: boolean;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private authentificationService: AuthenticationService,
    private clipboard: Clipboard,
    private connectionService: ConnectionService
  ) {}
  ngOnInit(): void {
    this.isLoggedIn = this.authentificationService.isLoggedIn();
    if (isDevMode()) {
      console.log("Development!");
    } else {
      console.log("Production!");
    }
    this.connectionService.getConnectionStatus().subscribe(status => {
      this.isConnected = status;
      if (!status) {
        console.log('Connection lost!');
      }
    });
    this.watcherUserLoggedStatut();
  }

  watcherUserLoggedStatut(): void {
    this.authentificationService.isloggedUser
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: boolean) => {
        this.isLoggedIn = data;
      });
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
