import {HttpErrorResponse} from "@angular/common/http";
import {ErrorHandler, Injectable} from "@angular/core";
import {AlertMessageService} from "../../components/alerte-message/alert-message.service";
import {AlertMessage, TypeAlert} from "../../models/alert-message";

@Injectable()
export class GlobalHandleHttpError implements ErrorHandler {
  alertMessage: AlertMessage;
  constructor(private alerteMessageService: AlertMessageService) {}

  handleError(error: HttpErrorResponse): void {
    console.error(error.message);
    const chunkFailedMessage = /Loading chunk [\d{1,}]+ failed/;
    const alertErrorMessage = {
      type: TypeAlert.ERROR,
      title: "Perte de connexion",
      text: "Vérifier votre connexion internet. Si celle-ci est opérationnelle, le serveur est indisponible.",
    }
    this.alertMessage = new AlertMessage();
    if (error.error && error.error.message) {
      this.alertMessage = {
        type: TypeAlert.ERROR,
        title: "Erreur",
        text: error.error.message,
      };
      this.alerteMessageService.pushAlerteMessage(this.alertMessage);
      console.error(error)
    } else {
      this.alerteMessageService.pushAlerteMessage(
        GlobalHandleHttpError.getHttpErrorCodeMessage(error.status)
      );
    }
    if (chunkFailedMessage.test(error.message)) {
      this.alerteMessageService.pushAlerteMessage(alertErrorMessage);
    } 
  }

  static getHttpErrorCodeMessage(code: number): AlertMessage {
    switch (code) {
      case 404:
        return {
          type: TypeAlert.ERROR,
          title: "Ressource non trouvée",
          text: "Impossible de traiter la demande, la ressource ciblée n'existe pas",
        };
      case 500:
        return {
          type: TypeAlert.ERROR,
          title: "Erreur serveur",
          text: "Une erreur s'est produite. Nous vous invitons à réessayer ultérieurement.",
        };
      case 503:
      case 504:
        return {
          type: TypeAlert.ERROR,
          title: "Site indisponible",
          text: "Le site est temporairement indisponible. Nous vous invitons à réessayer ultérieurement.",
        };
      case 400:
        return {
          type: TypeAlert.ERROR,
          title: "Syntaxe erronée",
          text: "La syntaxe de la requête est erronée. ",
        };
      case 401:
      case 403:
        return {
          type: TypeAlert.ERROR,
          title: "Accès refusé",
          text: "L'accès à cette ressource est interdit.",
        };
      case 0:
      case 408 : 
        return {
          type: TypeAlert.ERROR,
          title: "Perte de connexion",
          text: "Vérifier votre connexion internet. Si celle-ci est opérationnelle, le serveur est indisponible.",
        };
      default:
        return {
          type: TypeAlert.ERROR,
          title: "Erreur inconnue",
          text: "Désolé, une erreur inconnue est survenue.",
        };
    }
  }
}
