import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from "@angular/common/http";
import {Observable} from "rxjs";
import {finalize} from "rxjs/operators";
import {NgxSpinnerService} from "ngx-spinner";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  service_count = 0;
  requestsNotToBeIntercept = [
    "refreshtoken",
    "infosource-inputs",
    "backup/auto"
  ]
  constructor(private spinner: NgxSpinnerService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.service_count++;
    if (this.isRequestToIntercept(req.url)) {
      this.spinner.show();
    }

    return next.handle(req).pipe(
      finalize(() => {
        this.service_count--;
        if (this.service_count === 0) {
          this.spinner.hide();
        }
      })
    );
  }

  private isRequestToIntercept(url: string): boolean {
    let toIntercept = true;

    this.requestsNotToBeIntercept.forEach(request => {
      if(url.includes(request)) {
        toIntercept = false;
      }
    })

    return toIntercept;
  }
}
