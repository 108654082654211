import {Injectable} from "@angular/core";
import {NgbDateParserFormatter, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter{
  parse(value: string): NgbDateStruct {
    if (!value)
      return null
    const parts = value.split('/');
    return {day: +parts[0], month: +parts[1], year: +parts[2]} as NgbDateStruct

  }

  format(date: NgbDateStruct): string {
    return date ? ('0' + date.day).slice(-2) + "/" + ('0' + date.month).slice(-2) + "/" + date.year : null
  }
}
