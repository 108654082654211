<div>
  <div class="fr-grid-row fr-mb-6w">
    <div class="fr-col-3 fixed-column">
      <ol>
        <h3 style="color:#000091">Sommaire</h3>
        <div class="fr-accordions-group">
          <section class="fr-accordion" *ngFor="let thematique of listeQuestionResponse;let i=index ">
            <h4 class="fr-accordion__title">
              <button class="fr-accordion__btn" [attr.aria-expanded]="false" [attr.aria-controls]="'accordion-'+i" style="color:#000091">
                {{thematique.label}}
              </button>
            </h4>
            <div class="fr-collapse" id="accordion-{{i}}">
              <ul>
                <ng-container *ngFor="let item of thematique.questions; let j=index">
                  <li class="robot__question" (click)="goDown('section-'+i+j)" *ngIf="item.question.length > 1">
                    {{item.question}}</li>
                </ng-container>
              </ul>
            </div>
          </section>
        </div>
      </ol>
    </div>
    <div class="fr-col-9 fr-pl-4w">
      <div>
        <ng-container *ngFor="let thematique of listeQuestionResponse;let i=index ">
          <h4 class="fr-mb-2w" style="color:#000091">{{thematique.label}}</h4>
          <ng-container *ngFor="let item of thematique.questions; let j=index">
            <h5 id="{{'section-'+i+j}}" *ngIf="item.question.length > 1">{{item.question}}</h5>
            <div [innerHTML]="item.answerLong"></div>
          </ng-container>
        </ng-container>

      </div>
    </div>
  </div>
</div>