import {Injectable} from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AlerteDataLoaderService {
  message: Subject<string>;
  open: Subject<boolean>;
  lockButton: Subject<boolean>;

  constructor() {
    this.message = new Subject<string>();
    this.open = new Subject<boolean>();
    this.lockButton = new Subject<boolean>();
  }

  pushMessage(message: string): void {
    this.message.next(message);
  }
  pushOpen(open: boolean): void {
    this.open.next(open);
  }

  pushLockButton(lockButton: boolean): void {
    this.lockButton.next(lockButton);
  }
}
