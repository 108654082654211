import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {AlertMessage} from "src/app/models/alert-message";

@Injectable({
  providedIn: "root",
})
export class AlertMessageService {
  messageError: Subject<AlertMessage>;
  infinitDuration: Subject<boolean>;

  constructor() {
    this.messageError = new Subject<AlertMessage>();
    this.infinitDuration = new Subject<boolean>();
  }

  pushAlerteMessage(message: AlertMessage, duration?: boolean): void {
    if (message != null) {
      if(duration) {
        this.infinitDuration.next(duration);
      } else {
        this.infinitDuration.next(false);
      }

      this.messageError.next(message);
    }
  }
}
