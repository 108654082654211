import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {AlertMessage} from "src/app/models/alert-message";
import {AlerteAutoSaveService} from "src/app/components/alerte-auto-save/alerte-auto-save.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-alerte-auto-save',
  templateUrl: './alerte-auto-save.component.html',
  styleUrls: ['./alerte-auto-save.component.css']
})
export class AlerteAutoSaveComponent implements OnInit, OnDestroy {
  message = new AlertMessage();
  isDisplay = false;
  unsubscribe$ = new Subject<void>();

  constructor(
    private autoSaveService: AlerteAutoSaveService,
    private zone: NgZone) {
  }

  ngOnInit(): void {
    this.watcherMessageError();
    this.isDisplay = false;
  }

  watcherMessageError(): void {
    this.autoSaveService.message.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.message = data;
      this.isDisplay = true;
      this.zone.run(() => {
        setTimeout(() => (this.isDisplay = false), 5000);
      });
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
