/* istanbul ignore file */
import {animate, style, transition, trigger} from "@angular/animations";
import {Component, NgZone, OnInit} from "@angular/core";
import {Subject} from "rxjs";
import {AlertMessage, TypeAlert} from "../../models/alert-message";
import {AlertMessageService} from "./alert-message.service";

@Component({
  selector: "app-alerte-message",
  templateUrl: "./alerte-message.component.html",
  styleUrls: ["./alerte-message.component.css"],
  animations: [
    trigger("inOutAnimation", [
      transition(":enter", [
        style({opacity: 0}),
        animate("1s ease-out", style({opacity: 1})),
      ]),
      transition(":leave", [
        style({opacity: 1}),
        animate("1s ease-in", style({opacity: 0})),
      ]),
    ]),
  ],
})
export class AlerteMessageComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  messageAlert = new AlertMessage();
  infinitDuration = false;
  isDisplay = false;
  enumTypeAlert = TypeAlert;

  constructor(
    private alerteMessageService: AlertMessageService,
    private zone: NgZone
  ) {
  }

  ngOnInit(): void {
    this.watcherMessageError();
    this.watcherInfinitDuration()
    this.isDisplay = false;
  }

  watcherMessageError(): void {
    this.alerteMessageService.messageError.subscribe((message: AlertMessage): void => {
      this.messageAlert = message;
      this.isDisplay = true;
      window.scrollTo(0, 0);
      if (!this.infinitDuration) {
        this.zone.run(() => {
          setTimeout(() => (this.isDisplay = false), 5000);
        });
      }
    });
  }

  private watcherInfinitDuration(): void {
    this.alerteMessageService.infinitDuration.subscribe((duration: boolean): void => {
      this.infinitDuration = duration;
    })
  }
}
