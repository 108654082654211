import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {HasRoleDirective} from "./hasRole.directive";
import {RlaPageDirective} from "./rla-page.directive";
import {StatusColorDirective} from "./status-color";
import {CaptchaMakComponent} from "../components/captcha-mak/captcha-mak.component";
import {NgbdSortableHeaderUtilisateur} from "./sort-table-utilisateur";
import {NgbdSortableHeaderMaquette} from "./sort-table-maquette";
import {FilterColumnTotauxPipe} from "./filter-column-totaux.pipe";
import {NgbdSortableHeaderDonneesFinanciere} from "./sort-table-donnees-financiere";
import {StatusDonneesFinanciereColorDirective} from "./status-donnees-financiere-color";
import {CalculMontantTotauxCollectePipe} from "./directive-calcul/collect/calcul-total-amounts.pipe";
import {CalculColumnPipe} from "./directive-calcul/calcul-column.pipe";
import {CollectVoletBadgeDirective} from "./collect-volet-badge.directive";
import {CalculTotalGeneralPipe} from "./directive-calcul/calcul-total-general.pipe";
import {SetCpierValue} from "./set-cpier-value.directive.ts.directive";
import {DisabledControlDirective} from "./disabled-control.directive";
import {LockedFundingSourceDirective} from "./locked-funding-source.directive";
import {NoScrollInputDirective} from "./no-scroll-input.directive";
import {FilterFundingSourcePipe} from "./filter-funding-source.pipe";
import {LockedColumnDirective} from "./locked-column.directive";
import {UpdateCustomSearchCriteriaPipe} from "./update-custom-search-criteria.pipe";
import {GetAssociatedValuesPipe} from "./get-associated-values.pipe";
import {ColorOverspendingDirective} from "./color-overspending.directive";
import {CalculTotalAmountsGeneralPipe} from "./directive-calcul/collect/calcul-total-amounts-general.pipe";
import {FilterOperatorsPipe} from "./filter-operators.pipe";
import {FormatterDonneerCustomSearchPipe} from "./formatter-donneer-custom-search.pipe";
import {DistinctReferenceCityPipe} from "./distinct-reference-city.pipe";
import {FilterReferenceCityPipe} from './filter-reference-city.pipe';
import {StatusDashboardReloadColorDirective} from "./status-dashboard-reload-color";
import {SetReferenceCityAddOpeDirective} from './set-reference-city-add-ope.directive';
import {SetReferenceCityDirective} from './set-reference-city.directive';
import {FormaterDataTablePipe} from "./formater-data-table-pipe.pipe";
import {SortableDonneesDashboardDirective} from "./sortable-donnees-dashboard.directive";
import {AbbreviationEuroPipe} from './abbreviation-euro.pipe';
import {DistinctCofunderPipe} from './distinct-cofunder.pipe';
import {BackgroundValidateCellDirective} from './background-validate-cell.directive';
import {GetDateStructPipe} from './get-date-struct.pipe';
import {InvokeDirective} from "src/app/directives/invoke.directive";
import {HighlighterPipe} from './highlighter.pipe';
import {LockDeleteColumnPipe} from './lock-delete-column.pipe';
import {FormatMicroHeaderPipe} from './format-micro-header.pipe';
import {DropdownMatchWidthDirective} from './dropdown-match-width.directive';
import {GetFundingSourcesPipe} from './get-funding-sources.pipe';
import {DisableLevelPipe} from './disable-level.pipe';
import {CalculEnvelopePipe} from "src/app/directives/directive-calcul/collect/calcul-envelope.pipe.ts.directive";
import {OverspendingCollectDirective} from "src/app/directives/overspending-collect.directive";
import {GetInfoSourceItemsPipe} from './get-info-source-items.pipe';
import {GetLabelInfoSourcePipe} from './get-label-info-source.pipe';
import {GetAbbreviationPipe} from './get-abbreviation.pipe';
import {IsDashboardFilterToShowPipe} from './is-dashboard-filter-to-show.pipe';
import {IsUpdateInProgressPipe} from './is-update-in-progress.pipe';
import {GetColumnWidthPipe} from './get-column-width.pipe';
import {IsCellExistPipe} from './is-cell-exist.pipe';
import {GetContractsLabelPipe} from './get-contracts-label.pipe';
import {SplitFunctionalDomainPipe} from './split-functional-domain.pipe';
import {ChorusCalcColumnPipe} from './chorus-calc-column.pipe';
import { TruncatePipe } from "src/app/truncate.pipe";
import { OutSideClickDirective } from "./outside-click.directive";

@NgModule({
  imports: [CommonModule],
  declarations: [
    HasRoleDirective,
    RlaPageDirective,
    StatusColorDirective,
    StatusDonneesFinanciereColorDirective,
    CaptchaMakComponent,
    NgbdSortableHeaderUtilisateur,
    NgbdSortableHeaderMaquette,
    NgbdSortableHeaderDonneesFinanciere,
    FilterColumnTotauxPipe,
    CalculMontantTotauxCollectePipe,
    CalculColumnPipe,
    CollectVoletBadgeDirective,
    CalculTotalGeneralPipe,
    CalculEnvelopePipe,
    SetCpierValue,
    DisabledControlDirective,
    LockedFundingSourceDirective,
    NoScrollInputDirective,
    LockedColumnDirective,
    FilterFundingSourcePipe,
    UpdateCustomSearchCriteriaPipe,
    GetAssociatedValuesPipe,
    ColorOverspendingDirective,
    CalculTotalAmountsGeneralPipe,
    FilterOperatorsPipe,
    FormatterDonneerCustomSearchPipe,
    DistinctReferenceCityPipe,
    FilterReferenceCityPipe,
    StatusDashboardReloadColorDirective,
    SetReferenceCityAddOpeDirective,
    SetReferenceCityDirective,
    FormaterDataTablePipe,
    SortableDonneesDashboardDirective,
    AbbreviationEuroPipe,
    DistinctCofunderPipe,
    GetDateStructPipe,
    BackgroundValidateCellDirective,
    InvokeDirective,
    HighlighterPipe,
    LockDeleteColumnPipe,
    FormatMicroHeaderPipe,
    DropdownMatchWidthDirective,
    GetFundingSourcesPipe,
    DisableLevelPipe,
    OverspendingCollectDirective,
    GetInfoSourceItemsPipe,
    GetLabelInfoSourcePipe,
    GetAbbreviationPipe,
    IsUpdateInProgressPipe,
    GetColumnWidthPipe,
    IsCellExistPipe,
    IsDashboardFilterToShowPipe,
    GetContractsLabelPipe,
    SplitFunctionalDomainPipe,
    ChorusCalcColumnPipe,
    TruncatePipe,
    OutSideClickDirective
  ],
  exports: [
    HasRoleDirective,
    RlaPageDirective,
    StatusColorDirective,
    StatusDonneesFinanciereColorDirective,
    CaptchaMakComponent,
    NgbdSortableHeaderUtilisateur,
    NgbdSortableHeaderMaquette,
    NgbdSortableHeaderDonneesFinanciere,
    FilterColumnTotauxPipe,
    CalculMontantTotauxCollectePipe,
    CalculColumnPipe,
    CollectVoletBadgeDirective,
    CalculTotalGeneralPipe,
    CalculEnvelopePipe,
    SetCpierValue,
    DisabledControlDirective,
    LockedFundingSourceDirective,
    LockedColumnDirective,
    FilterFundingSourcePipe,
    NoScrollInputDirective,
    UpdateCustomSearchCriteriaPipe,
    GetAssociatedValuesPipe,
    ColorOverspendingDirective,
    CalculTotalAmountsGeneralPipe,
    FilterOperatorsPipe,
    DistinctReferenceCityPipe,
    FilterReferenceCityPipe,
    StatusDashboardReloadColorDirective,
    SetReferenceCityAddOpeDirective,
    SetReferenceCityDirective,
    FormaterDataTablePipe,
    SortableDonneesDashboardDirective,
    AbbreviationEuroPipe,
    DistinctCofunderPipe,
    GetDateStructPipe,
    BackgroundValidateCellDirective,
    InvokeDirective,
    HighlighterPipe,
    LockDeleteColumnPipe,
    FormatMicroHeaderPipe,
    DropdownMatchWidthDirective,
    GetFundingSourcesPipe,
    DisableLevelPipe,
    OverspendingCollectDirective,
    GetInfoSourceItemsPipe,
    GetLabelInfoSourcePipe,
    GetAbbreviationPipe,
    IsUpdateInProgressPipe,
    GetColumnWidthPipe,
    IsCellExistPipe,
    IsDashboardFilterToShowPipe,
    GetContractsLabelPipe,
    SplitFunctionalDomainPipe,
    ChorusCalcColumnPipe,
    TruncatePipe,
    OutSideClickDirective
  ],
})
export class DirectiveModule {}
