import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";


@Injectable({
  providedIn: 'root'
})

export class ConnectionService {

    private connectionStatus$: BehaviorSubject<boolean>;

    constructor() {
        this.connectionStatus$ = new BehaviorSubject<boolean>(navigator.onLine);
        window.addEventListener('online', () => this.updateConnectionStatus());
        window.addEventListener('offline', () => this.updateConnectionStatus());
      }

      getConnectionStatus(): Observable<boolean> {
        return this.connectionStatus$.asObservable();
      }
      
      private updateConnectionStatus(): void {
        this.connectionStatus$.next(navigator.onLine);
      }
}