import {AfterViewInit, OnDestroy, Component, OnInit} from "@angular/core";
import {GetListHelpAnswerService} from "src/app/services/common/get-list-help-answer.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {HelpMe} from "src/app/models/help-me";
import {ActivatedRoute, NavigationStart, Router} from "@angular/router";

@Component({
  selector: "app-robot-aide-en-ligne",
  templateUrl: "./robot-aide-en-ligne.component.html",
  styleUrls: ["./robot-aide-en-ligne.component.css"],
})
export class RobotAideEnLigneComponent implements OnInit, AfterViewInit, OnDestroy {
  afficherAideEnlignePopup = false;
  unsubscribe$ = new Subject<void>();
  listeQuestionResponse: HelpMe[] = [];
  showRobot = true;
  constructor(
    private getListHelpAnswerService: GetListHelpAnswerService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.getListeQuestionResponse();
  }

  ngAfterViewInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.url.includes("aide")) {
          this.showRobot = false;
        } else {
          this.showRobot = true;
        }
      }
    });
  }

  getListeQuestionResponse(): void {
    this.getListHelpAnswerService
      .getJSON()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (data) => {
          this.listeQuestionResponse = data;
        },
      });
  }

  openAideEnlignePage(section: string): void {
    this.afficherAideEnlignePopup = false;
    // Converts the route into a string that can be used
    // with the window.open() function
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/aide-en-ligne/${section}`])
    );

    window.open(url, "_blank");
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
