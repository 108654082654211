export class AlertMessage {
  title: string;
  text: string;
  type: TypeAlert;
}

export enum TypeAlert {
  ERROR,
  SUCESS,
  WARNING,
}
