/* istanbul ignore file */

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { RoleProfilEnum } from "./models/enum/role-profil.enum";
import { AuthGuard } from "./services/common/auth-guard";
import { RoleGuard } from "./services/common/role-guard.guard";

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./pages/home/home.module").then((m) => m.HomeModule),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "accounts",
    loadChildren: () =>
      import("./pages/connexion/connexion.module").then(
        (m) => m.ConnexionModule
      ),
    data: { breadcrumb: "Connexion" },
    canActivate: [],
  },
  {
    path: "maquettes-financieres",
    loadChildren: () =>
      import("./pages/maquettes-financieres/maquettes-financieres.module").then(
        (m) => m.MaquettesFinancieresModule
      ),
    data: { breadcrumb: "Maquettes financières" },
    canActivate: [AuthGuard],
  },
  {
    path: "collecte-donnees",
    loadChildren: () =>
      import("./pages/collecte-donnees/collecte-donnees.module").then(
        (m) => m.CollecteDonneesModule
      ),
    data: { breadcrumb: "Données d'exécution financière" },
    canActivate: [AuthGuard],
  },
  {
    path: "tableau-de-bord",
    loadChildren: () =>
      import("./pages/tableau-de-bord/tableau-de-bord.module").then(
        (m) => m.TableauDeBordModule
      ),
    data: { breadcrumb: "Tableau de bord" },
    canActivate: [AuthGuard],
  },
  {
    path: "aide-en-ligne",
    loadChildren: () =>
      import("./pages/aide-en-ligne/aide-en-ligne.module").then(
        (m) => m.AideEnLigneModule
      ),
    data: { breadcrumb: "Aide en ligne" },
    canActivate: [AuthGuard],
  },
  {
    path: "accessibilite",
    loadChildren: () =>
      import("./pages/accessibilite/accessibilite.module").then(
        (m) => m.AccessibiliteModule
      ),
    data: { breadcrumb: "Accessibilite" },
    canActivate: [],
  },
  {
    path: "donnees-personnelles",
    loadChildren: () =>
      import("./pages/donnees-personnelles/donnees-personnelles.module").then(
        (m) => m.DonneesPersonnellesModule
      ),
    data: { breadcrumb: "Données personnelles" },
    canActivate: [],
  },
  {
    path: "contact",
    loadChildren: () =>
      import("./pages/contact/contact.module").then(
        (m) => m.ContactModule
      ),
    data: { breadcrumb: "Contact" },
    canActivate: [],
  },
  {
    path: "agent-connect",
    loadChildren: () =>
      import("./pages/agent-connect/agent-connect.module").then(
        (m) => m.AgentConnectModule
      ),
    data: { breadcrumb: "Agent-connect" },
    canActivate: [],
  },
  {
    path: "mentions-legales",
    loadChildren: () =>
      import("./pages/mentions-legales/mentions-legales.module").then(
        (m) => m.MentionsLegalesModule
      ),
    data: { breadcrumb: "Mentions-légales" }
  },
  {
    path: "administration-SI",
    loadChildren: () =>
      import("./pages/administration-SI/administration-SI.module").then(
        (m) => m.AdministrationSIModule
      ),
    data: {
      breadcrumb: "Administer le SI",
      roles: RoleProfilEnum.ROLE_ADMIN_NATIONAL
    },
    canActivate: [AuthGuard, RoleGuard]
  },
  {
    path: "gestion-utilisateurs",
    loadChildren: () =>
      import("./pages/gestion-utilisateurs/gestion-utilisateurs.module").then(
        (m) => m.GestionUtilisateursModule
      ),
    data: {
      breadcrumb: "Gestion des utilisateurs",
      roles: RoleProfilEnum.ROLE_ADMIN_REGIONAL
    },
    canActivate: [AuthGuard, RoleGuard],
  },

  { path: "**", component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      scrollPositionRestoration: "top",
      anchorScrolling: "enabled",
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
